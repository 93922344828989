import { Amplify, ResourcesConfig } from "aws-amplify";
import web from "./awsconfig";
import { Authenticator } from "@aws-amplify/ui-react";
import Main from "./main";
import { QueryClient, QueryClientProvider } from "react-query";
import { AlertProvider } from "./Component/ContextAPI/ContextApi";
import { StoreIdProvider } from "./Component/ContextAPI/StoreIdContext";
import { UserInfoProvider } from "./Component/ContextAPI/UserContext";
import { useEffect, useMemo, useCallback, useRef } from "react";
import { handleNoCurrentUserError } from "./Api/utils";
import { Routers } from "./Routes/Routes";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import { fetchAuthSession } from "aws-amplify/auth";
import { updateUser } from "./Api/User/mutations";

Amplify.configure(web as ResourcesConfig);

function App() {
  console.log('Corrected QA')
  const queryClient = useMemo(() => new QueryClient(), []);
  const excludedPaths = useMemo(
    () => [Routers?.login, Routers?.forgotpassword],
    [],
  );

  // Flag to prevent multiple FCM token fetches
  const isFetchingToken = useRef(false);
  let index = process.env.REACT_APP_STACK
    ? `user${process.env.REACT_APP_STACK}`
    : "user";

  useEffect(() => {
    const checkUserSession = async () => {
      const currentPath = window.location.pathname;
      if (!excludedPaths.includes(currentPath)) {
        await handleNoCurrentUserError();
      }
    };
    checkUserSession();
  }, [excludedPaths]);

  const fetchFCMToken = useCallback(async () => {
    if (isFetchingToken.current) {
      console.log("FCM token fetch is already in progress.");
      return;
    }

    const existingToken = localStorage.getItem("FCMToken");
    if (existingToken) {
      console.log("FCM Token already exists:", existingToken);
      return; // Prevent re-fetch
    }

    try {
      isFetchingToken.current = true; // Set flag to true
      console.log("Waiting for Service Worker activation...");
      await navigator.serviceWorker.ready;

      console.log("Attempting to fetch FCM token...");
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
        serviceWorkerRegistration: await navigator.serviceWorker.ready,
      });

      if (token) {
        console.log("FCM Token fetched successfully:", token);
        localStorage.setItem("FCMToken", token); // Save token
      } else {
        console.warn("FCM Token fetch returned null.");
      }
    } catch (err) {
      console.error("Error fetching FCM token:", err);
    } finally {
      isFetchingToken.current = false; // Reset flag
    }
  }, []);

  useEffect(() => {
    const requestNotificationPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        await fetchFCMToken();
      } else {
        console.error("Notification permission denied.");
      }
    };

    requestNotificationPermission();
  }, [fetchFCMToken]);

  useEffect(() => {
    const existingToken = localStorage.getItem("FCMToken");
    if (!existingToken && Notification.permission === "granted") {
      console.log("Token missing after reload, fetching again...");
      fetchFCMToken();
    }
  }, [fetchFCMToken]);

  useEffect(() => {
    const updateUserData = async () => {
      try {
        const session = await fetchAuthSession({ forceRefresh: true });
        const idTokenPayload = session?.tokens?.idToken?.payload;
        if (!idTokenPayload) {
          console.log("No ID token payload available");
          return;
        }
        const {
          name,
          email,
          phone_number: phone,
          sub: userId,
        } = idTokenPayload;

        // Ensure `index` is defined or passed into this effect
        const CreatePreSignUpUserInput = {
          group: "admin",
          email,
          fullName: name,
          username: email,
          phone,
          entityName: index, // Ensure `index` is defined or passed properly
          isOnline: true,
          id: userId,
          deviceToken: localStorage.getItem("FCMToken"),
        };

        const res: any = await updateUser(
          "Admin",
          `Active#01`,
          CreatePreSignUpUserInput,
        );

        const result = JSON.parse(res?.data?.updateUser);
        const errorMessage = JSON.parse(result.body).error;

        if (result?.statusCode === 200) {
          console.log("User successfully updated.");
        } else {
          console.error(
            "Error updating user:",
            errorMessage || "Unknown error",
          );
        }
      } catch (error: any) {
        console.error(
          "An error occurred while updating the user:",
          error.message,
        );
      }
    };

    updateUserData();
  }, [index]);

  return (
    <div className="w-100 d-flex flex-column min-vh-100">
      <QueryClientProvider client={queryClient}>
        <Authenticator.Provider>
          <AlertProvider>
            <StoreIdProvider>
              <UserInfoProvider>
                <Main />
              </UserInfoProvider>
            </StoreIdProvider>
          </AlertProvider>
        </Authenticator.Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
